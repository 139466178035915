<template>
  <RevDrawer
    v-if="hasLegalModal"
    :close-button-label="i18n(dsTranslations.close)"
    :name
    :title="i18n(translations.modalTitle)"
  >
    <template #trigger="{ open }">
      <RevLink class="mt-24 flex items-center text-left" @click="open">
        <IconInfo class="mr-8" /> {{ i18n(translations.modalTrigger) }}
      </RevLink>
    </template>

    <template #body>
      <p class="mt-32">{{ i18n(translations.modalOrderExplained) }}</p>
      <p class="mt-32">{{ i18n(translations.modalWhoComments) }}</p>

      <RevLink class="mt-16 block" target="_blank" :to="pdfLink">
        {{ i18n(translations.legalLink) }}
      </RevLink>
    </template>
  </RevDrawer>
</template>

<script lang="ts" setup>
import { useRoute, useRouteLocationWithLocale } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevDrawer } from '@ds/components/Drawer'
import { RevLink } from '@ds/components/Link'
import { IconInfo } from '@ds/icons/IconInfo'
import { dsTranslations } from '@ds/translations'

import { CMS } from '~/scopes/cms/routes-names'
import { LEGAL_PAGE_NAME } from '~/scopes/legal/constants'

import { COUNTRIES_WITH_REVIEWS_LEGAL_MODAL } from '../../constants'

import translations from './ReviewsLegalModal.translations'

const i18n = useI18n()
const country = useMarketplace().market.countryCode
const route = useRoute()
const resolveLocalizedRoute = useRouteLocationWithLocale()
const name = computed(() => `REVIEWS_LEGAL_INFORMATION_${route.name as string}`)
const hasLegalModal = computed(() =>
  COUNTRIES_WITH_REVIEWS_LEGAL_MODAL.includes(country),
)

const pdfLink = resolveLocalizedRoute({
  name: CMS.LEGAL_PAGE,
  params: {
    pageName: LEGAL_PAGE_NAME.OTHER,
  },
})
</script>
